import * as React from "react";
import { Sheet } from "strcss";
import { IPlayer } from "lokomotiv-packages";

export interface IProps {
  player: IPlayer;
  id: string;
  height?: string;
  className?: string;
  onClick?: (playerId: string) => void;
}

export default class PlayerCardComponent extends React.PureComponent<IProps> {
  private baseUrl = "/images/players/";

  render() {
    const { player, onClick } = this.props;
    return (
      <div
        className={[sheet.map.playerCard, this.props.className].join(" ")}
        id={this.props.id}
        style={this.props.height ? { height: this.props.height } : {}}
        onClick={() => {
          if (typeof onClick !== "undefined") {
            onClick(player._id);
          }
        }}
      >
        <img
          src={this.baseUrl + player.imagePaths?.[0]}
          className={sheet.map.image}
          alt={player.firstName + player.lastName}
        />
        <div className={sheet.map.imageInfoWrapper}>
          {player.number && (
            <div className={sheet.map.playerNumber}>
              <b>{player.number < 10 && "0" + player.number}</b>
              <b>{player.number >= 10 && player.number}</b>
            </div>
          )}
          <div className={sheet.map.playerName}>
            <div>{player.firstName}</div>
            <span>&nbsp;</span>
            <div>
              <b>{player.lastName}</b>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const sheet = new Sheet(`
  map playerCard 
    display inlineBlock
    position relative

    height 100%
  map image
    height 100%
    objectFit contain
    verticalAlign middle
  map imageInfoWrapper
    position absolute
    bottom 0px
    width 100%
    height 40%
    backgroundImage linear-gradient(to bottom, rgba(0,0,0,0.3), rgba(0,0,0,1))
    color white
  map playerNumber 
    display flex
    justifyContent center
    fontSize 34  
    height 30% 
  map playerName
    display flex 
    justifyContent center
    textAlign center
    fontSize 32
    height 30%
`);

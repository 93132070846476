import * as React from "react";
import { Sheet } from "strcss";
import { IPlayer } from "lokomotiv-packages";
import PlayerCardComponent from "./player-card.component";
import { Link } from "react-router-dom";

export interface IProps {
  players: IPlayer[];
  title?: string;
  showHeaderNav?: boolean;
  startCenter?: boolean;
  playerSliderStyle?: any;
  onClickPlayer?: (id: string) => void;
}

export interface IState {
  marginLeftCentering: string;
}

export default class PlayersSliderComponent extends React.PureComponent<
  IProps,
  IState
> {
  private ref: HTMLDivElement | null = null;

  previous() {
    const image = document.getElementById("image-0");
    if (!image || !this.ref) {
      return;
    }

    const margin = 10;
    const imageWidth: number = image.offsetWidth + margin;
    const scrollingAreaWidth = this.ref.clientWidth ?? 0;

    const currentXPosition = this.ref.scrollLeft;
    const currentImagesInView =
      Math.floor(scrollingAreaWidth / imageWidth) || 1;
    const nextXPosition = currentXPosition - currentImagesInView * imageWidth;
    this.ref?.scrollTo({ left: nextXPosition, behavior: "smooth" });
  }

  next() {
    const image = document.getElementById("image-0");
    if (!image || !this.ref) {
      return;
    }

    const margin = 10;
    const imageWidth: number = image.offsetWidth + margin;
    const scrollingAreaWidth = this.ref.clientWidth ?? 0;

    const currentXPosition = this.ref.scrollLeft;
    const currentImagesInView =
      Math.floor(scrollingAreaWidth / imageWidth) || 1;
    console.log({ currentImagesInView });
    const nextXPosition = currentXPosition + currentImagesInView * imageWidth;
    this.ref?.scrollTo({ left: nextXPosition, behavior: "smooth" });

    this.ref.scrollTo({ left: nextXPosition, behavior: "smooth" });
  }

  public render() {
    const { players, showHeaderNav } = this.props;
    return (
      <div className={sheet.map.playersSliderComponent}>
        <div
          className={sheet.maps(sheet.map.playerSliderWrapper)}
          style={this.props.playerSliderStyle || null}
          ref={(ref) => (this.ref = ref)}
        >
          {players.length > 0 &&
            players.map((_player, i) => {
              const isLastItem = i === players.length - 1;

              return (
                <Link to={`/players/${_player._id}`} key={_player._id}>
                  <PlayerCardComponent
                    player={_player}
                    id={"image-" + i}
                    key={i}
                    className={!isLastItem && sheet.map.playerCardMargin}
                  />
                </Link>
              );
            })}
        </div>
        {showHeaderNav === true && (
          <div className={sheet.map.navContainer}>
            <div className={sheet.map.nav}>
              <img
                className={sheet.map.navBtn}
                src={"/svgs/back-arrow-circular.svg"}
                onClick={() => this.previous()}
                alt={"arrow-back"}
              />
              <img
                className={sheet.map.navBtn}
                src={"/svgs/right-arrow-circular.svg"}
                onClick={() => this.next()}
                alt={"arrow-forward"}
              />
            </div>
          </div>
        )}
      </div>
    );
  }
}

const sheet = new Sheet(`
  map playersSliderComponent
    width 100%
  map navContainer
    display flex
    justifyContent center
    marginTop 20
    marginBottom 40
  map nav 
    display flex
    position relative
    justifyContent center
    gap 20
    width 120
    height 40
  map left 
    flex 1
  map right 
    display flex
    justifyContent flexEnd
    alignItems center
    flex 1    
  map navBtn
    height 100%
  map playerSliderWrapper
    display flex
    margin 0 30
    whiteSpace nowrap
    overflowX hidden
    overflowY hidden
    overflowX scroll !mobile
    -webkit-overflow-scrolling touch !mobile
    width auto   
  map playerCardMargin 
    marginRight 10
`);

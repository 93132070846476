import * as React from "react";
import { Sheet } from "strcss";

export interface IProps {
  text: string;
}

export interface IState {}

export default class TitleComponent extends React.PureComponent<
  IProps,
  IState
> {
  render() {
    return (
      <div className={sheet.map.wrapper}>
        <h2 className={sheet.map.title}>
          {this.props.text.toLocaleUpperCase()}
        </h2>
      </div>
    );
  }
}

const sheet = new Sheet(`
  map wrapper 
    marginBottom 20px
  map title
    color white  
    fontSize 1.2rem
    textAlign center
`);

import { ICrate, IPlayer, Position } from "lokomotiv-packages";
import * as React from "react";
import { Sheet } from "strcss";
import { enumHelper } from "../helpers/enumHelper";

export interface IProps {
  player: IPlayer;
  openCrates: ICrate[];
  closedCrates: ICrate[];
}

export interface IState {
  activeTab: number;
}

export default class PlayerStatisticsComponent extends React.PureComponent<
  IProps,
  IState
> {
  public state: IState = {
    activeTab: 0,
  };

  private nameFontSize = "40px";

  componentWillMount() {
    if (window.innerWidth < 500) {
      const { player } = this.props;
      const fullPlayerName =
        player.firstName + " " + player.lastName + " " + player.number;
      if (fullPlayerName.length > 15) {
        this.nameFontSize = "35px";
      }
    }
  }

  render() {
    const { player, openCrates, closedCrates } = this.props;
    return (
      <div className={sheet.map.statics}>
        <div className={sheet.map.positionNameWrapper}>
          {!isNaN(player.position ?? 0) && (
            <div className={sheet.map.position}>
              {enumHelper
                .convertPosition(Position[player.position ?? 0])
                .toLocaleUpperCase()}
            </div>
          )}
          <div
            className={sheet.map.text}
            style={{ fontSize: this.nameFontSize }}
          >
            <b>{player.number ? player.number : ""}</b> {player.firstName}{" "}
            <b>{player.lastName}</b>
          </div>
        </div>
        <div className={sheet.map.statisticsWrapper}>
          {openCrates !== null && (
            <div
              className={sheet.map.tab}
              onClick={() => {
                this.setState({
                  activeTab: 0,
                });
              }}
            >
              <div className={sheet.map.tabText}>Openstaand</div>
              <div className={sheet.map.amount}>{openCrates.length}</div>
              <div className={sheet.map.activeLine}></div>
            </div>
          )}
          {closedCrates !== null && (
            <div
              className={sheet.map.tab}
              onClick={() => {
                this.setState({
                  activeTab: 1,
                });
              }}
            >
              <div className={sheet.map.tabText}>Ingeleverd</div>
              <div className={sheet.map.amount}>{closedCrates.length}</div>
              <div className={sheet.map.activeLine}></div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

const sheet = new Sheet(`
  map statics 
    display flex
    flexDirection column
    alignItems center
    justifyContent center
    background rgba(8,14,31,.9)
    width 95vw 
    margin auto
    borderRadius 15px 15px 0 0
    overflow hidden
  map positionNameWrapper 
    padding 10 0  
    textAlign center
  map position 
    color white
    fontSize 25px
  map text
    marginBottom 10px
    color white
    fontSize 40px
    textAlign center
  map statisticsWrapper
    display flex
    justifyContent center
    width 300px
    width 500px !tablet
    width 500px !desktop
    height 100px
    left 100px
    bottom 100px
  map tab
    width 25%
    height 100px
    cursor pointer
    userSelect none
    borderBottom 2px solid #fdc52c !hover
  map active 
    borderBottom 2px solid #fdc52c
  map amount 
    color #fdc52c  
    fontSize 60px
    textAlign center
    fontFamily superstar
  map tabText
    color white 
    fontSize 12px
    fontSize 18px !tablet
    fontSize 18px !desktop
    textTransform uppercase
    fontFamily superstar    
    textAlign center  
  map detailsWrapper 
    width 100%
    padding 40 20
    borderRadius 0 0 20px 20px 
    overflow hidden
    textAlign center 
  map noResults 
    fontSize 24px
    fontWeight bold
    color white  
`);

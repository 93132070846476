import * as React from "react";
import { Sheet } from "strcss";
import {
  Navigate,
  RouterProvider,
  createBrowserRouter,
} from "react-router-dom";
import HomeView from "./views/home.view";
import PlayersView from "./views/players.view";
import PlayerDetailView from "./views/player-detail.view";
export default class App extends React.Component {
  router = createBrowserRouter([
    {
      path: "/",
      element: <HomeView />,
    },
    {
      path: "/players",
      element: <PlayersView />,
    },
    {
      path: "/players/:id",
      element: <PlayerDetailView />,
    },
    {
      path: "*",
      element: <Navigate to={"/"} replace={true} />,
    },
  ]);

  public render() {
    return (
      <div className={sheet.map.app}>
        <RouterProvider router={this.router}></RouterProvider>
      </div>
    );
  }
}

const sheet = new Sheet(`
  map app 
    display flex
    fontFamily 'Titillium Web', sans-serif
    width 100%
    height 100%
`);

// modules
import { IHttpOptions, IResponse } from "lokomotiv-packages";

interface IRequestInit {
  method: string;
  headers: {
    "Content-Type": string;
    accept: string;
  };
  body?: string;
}

export const httpService = new (class HttpService {
  /**
   * Executes an http request
   * @param method
   * @param query
   * @param data
   */
  private async send<Model>(
    method: string,
    query: string,
    data = {},
    options: IHttpOptions = {}
  ): Promise<IResponse<Model>> {
    query += this.convertOptions(options);
    const requestInit: IRequestInit = {
      method,
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
      },
    };

    if (method !== "GET" && data) {
      requestInit["body"] = JSON.stringify(data);
    }

    const response: IResponse<Model> = await fetch(
      [process.env.REACT_APP_API_URL, query].join("/"),
      requestInit
    );

    if (response.status >= 400) {
      // error occurred
      throw await response.json();
    }

    return await response;
  }

  /**
   * Executes a GET request
   * @param query
   */
  public getRequest<Model>(
    query: string,
    options: IHttpOptions = {}
  ): Promise<IResponse<Model>> {
    return this.send<Model>("GET", query, {}, options);
  }

  /**
   * Executes a POST request
   * @param query
   * @param body
   */
  public postRequest<Model>(
    query: string,
    body: any,
    options: IHttpOptions = {}
  ): Promise<IResponse<Model>> {
    return this.send<Model>("POST", query, body, options);
  }

  /**
   * Executes a PUT request
   * @param query
   * @param body
   */
  public putRequest<Model>(
    query: string,
    body: any,
    options: IHttpOptions = {}
  ): Promise<IResponse<Model>> {
    return this.send<Model>("PUT", query, body, options);
  }

  /**
   * Executes a DELETE request
   * @param query
   */
  public deleteRequest<Model>(
    query: string,
    options: IHttpOptions = {}
  ): Promise<IResponse<Model>> {
    return this.send<Model>("DELETE", query, {}, options);
  }

  /**
   * Convert options to query params
   * @param options
   */
  private convertOptions(options: IHttpOptions) {
    const params = [];
    for (const option of Object.keys(options)) {
      params.push(
        [
          option,
          Array.isArray(options[option])
            ? options[option].join(",")
            : options[option],
        ].join("=")
      );
    }

    return params.length ? `?${params.join("&")}` : "";
  }
})();

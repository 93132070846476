import { IPlayer, Position } from "lokomotiv-packages";
import * as React from "react";
import { Sheet } from "strcss";
import { playerService } from "../services/player.service copy";
import PlayersSliderComponent from "../components/player-slider.component";
import TitleComponent from "../components/title.component";
import PlayerCardComponent from "../components/player-card.component";
import LoaderComponent from "../components/loader.component";
import PageComponent from "../components/page.component";
import { Link } from "react-router-dom";
import PageBodyComponent from "../components/page-body.component";

export interface IProps {}

export interface IState {
  trainers: IPlayer[];
  goalKeepers: IPlayer[];
  defenders: IPlayer[];
  midfielders: IPlayer[];
  attackers: IPlayer[];
  substitutes: IPlayer[];
  isLoading: boolean;
}

export default class PlayersView extends React.PureComponent<IProps, IState> {
  public state: IState = {
    trainers: [] as IPlayer[],
    goalKeepers: [] as IPlayer[],
    defenders: [] as IPlayer[],
    midfielders: [] as IPlayer[],
    attackers: [] as IPlayer[],
    substitutes: [] as IPlayer[],
    isLoading: true,
  };

  async componentDidMount() {
    try {
      const _players = await (await playerService.getAll()).json();
      this.setState({
        trainers: _players.filter(
          (player) => player.position === Position.TRAINER
        ),
        goalKeepers: _players.filter(
          (player) => player.position === Position.GOAL
        ),
        defenders: _players.filter(
          (player) => player.position === Position.DEFENSE
        ),
        midfielders: _players.filter(
          (player) => player.position === Position.MIDFIELD
        ),
        attackers: _players.filter(
          (player) => player.position === Position.ATTACK
        ),
        substitutes: _players.filter(
          (player) => player.position === Position.SUBSTITUTE
        ),
        isLoading: false,
      });
    } catch (error) {
      console.log(error);
    }
  }

  public render() {
    const {
      trainers,
      goalKeepers,
      defenders,
      midfielders,
      attackers,
      substitutes,
    } = this.state;
    if (this.state.isLoading === true) {
      return <LoaderComponent />;
    }
    return (
      <PageComponent>
        <PageBodyComponent>
          {trainers.length > 0 && (
            <React.Fragment>
              <TitleComponent text={"Trainer"} />
              <Link
                to={`/players/${trainers[0]._id}`}
                className={sheet.map.singlePlayerWrapper}
              >
                <PlayerCardComponent
                  id={trainers[0]._id}
                  player={trainers[0]}
                  height={"300px"}
                />
              </Link>
            </React.Fragment>
          )}

          {goalKeepers.length > 0 && (
            <React.Fragment>
              <TitleComponent text={"Goalkeepers"} />
              <Link
                to={`/players/${goalKeepers[0]._id}`}
                className={sheet.map.singlePlayerWrapper}
              >
                <PlayerCardComponent
                  id={goalKeepers[0]._id}
                  player={goalKeepers[0]}
                  height={"300px"}
                />
              </Link>
            </React.Fragment>
          )}
          {defenders.length > 0 && (
            <React.Fragment>
              <TitleComponent text={"Defenders"} />
              <PlayersSliderComponent
                players={defenders}
                showHeaderNav={true}
                playerSliderStyle={{ height: "300px" }}
              />
            </React.Fragment>
          )}

          {midfielders && midfielders.length > 0 && (
            <React.Fragment>
              <TitleComponent text={"Midfielders"} />
              <PlayersSliderComponent
                players={midfielders}
                showHeaderNav={true}
                playerSliderStyle={{ height: "300px" }}
                startCenter
              />
            </React.Fragment>
          )}
          {attackers.length > 0 && (
            <React.Fragment>
              <TitleComponent text={"attackers"} />
              <PlayersSliderComponent
                players={attackers}
                showHeaderNav={true}
                playerSliderStyle={{ height: "300px" }}
                startCenter
              />
            </React.Fragment>
          )}
          {substitutes.length > 0 && (
            <React.Fragment>
              <TitleComponent text={"SUBSTITUTES"} />
              <PlayersSliderComponent
                players={substitutes}
                showHeaderNav={true}
                playerSliderStyle={{ height: "300px" }}
              />
            </React.Fragment>
          )}
        </PageBodyComponent>
      </PageComponent>
    );
  }
}

const sheet = new Sheet(`
  map container 
    marginTop 35px !mobile
    marginBottom 40px
  map header
    color white  
    fontSize 1.4rem
    textAlign center
    marginBottom 10px
  map singlePlayerWrapper
    padding 0 50 !tablet
    padding 0 100 !desktop
    display flex
    justifyContent center  
  map playersWrapper 
    display flex 
    justifyContent center
    gap 20 
    flexWrap wrap
    flexDirection row
    textAlign center
    display none !mobile
  map playersWrapperMobile 
    display none !tablet
    display none !desktop 
  map playerCardWrapper
    padding 10
  map playerSlider 
    display flex
    justifyContent center  
`);

import * as React from "react";
import { Sheet } from "strcss";
import MobileMenu from "../statics/mobile-menu";

export interface IProps {}

export interface IState {}

export default class HeaderComponentMobile extends React.PureComponent<
  IProps,
  IState
> {
  public render() {
    return (
      <div className={sheet.map.headerComponent}>
        <div className={sheet.map.container}>
          <div className={sheet.map.bars} onClick={MobileMenu.open}>
            <div className={sheet.map.bar} />
            <div className={sheet.map.bar} />
            <div className={sheet.map.bar} />
          </div>
          <div className={sheet.map.logoWrapper}>
            <div className={sheet.map.logo} />
          </div>
        </div>
        <div className={sheet.map.bottom}>
          <div className={sheet.map.bottomLine} />
        </div>
      </div>
    );
  }
}

const sheet = new Sheet(`
  map headerComponent
    display none
    display flex !mobile
    position fixed
    backgroundColor #203355
    flexDirection column
    size 100% 50px
    rect 0 0 auto 0
    zIndex 10
    width 100%
    border 1px solid
  map container
    position relative
    display flex
    width 100% 
    height 100%
    flexDirection row
  map bars 
    display flex 
    justifyContent center
    alignItems center
    flexDirection column
    cursor pointer  
    marginLeft 5px
    marginTop auto
    marginBottom auto
    zIndex 1
  map bar 
    width 35px
    height 4px
    backgroundColor white
    margin 6px 0 0 6px
    opacity 1
  map logoWrapper
    padding 5px
    height 150%  
    width 100vw
    position absolute
    textAlign center
    display flex
    justifyContent center
    zIndex -1
  map logo 
    image /images/logo.png
    backgroundPosition left center
    width 60px
    height 100% 
    opacity 1
  `);

import * as React from "react";
import { Link } from "react-router-dom";
import { Sheet } from "strcss";

export interface IProps {}

export interface IState {}

export default class HeaderComponent extends React.PureComponent<
  IProps,
  IState
> {
  public state: IState = {};

  onClickFacebookButton() {
    const win = window.open(
      "https://www.facebook.com/LokomotivAmby3",
      "_blank"
    );
    win?.focus();
  }

  public render() {
    return (
      <div className={sheet.map.headerComponent}>
        <div className={sheet.map.container}>
          <Link to={"/"}>
            <div className={sheet.map.left}>
              <div className={sheet.map.logoWrapper}>
                <div className={sheet.map.logo} />
              </div>
              <div className={sheet.map.logoText}>LOKOMOTIV AMBY</div>
            </div>
          </Link>

          <div className={sheet.map.right}>
            <div className={sheet.map.rightTop}>
              <div className={sheet.map.followText}>FOLLOW LOKOMOTIV AMBY</div>
              <div className={sheet.map.socialMediaIconWrapper}>
                <div
                  className={sheet.map.facebook}
                  onClick={() => this.onClickFacebookButton()}
                />
              </div>
            </div>
            <div className={sheet.map.rightBottom}>
              <Link to="/" className={sheet.map.menuButton}>
                Home
              </Link>
              <Link to="/players" className={sheet.map.menuButton}>
                Players
              </Link>
            </div>
          </div>
        </div>
        <div className={sheet.map.bottom}>
          <div className={sheet.map.bottomLine} />
        </div>
      </div>
    );
  }
}

const sheet = new Sheet(`
  map headerComponent
    display flex
    display none !mobile
    position fixed
    backgroundColor #203372
    flexDirection column
    size 100% 120
    rect 0 0 auto 0
    zIndex 10
    width 100%
  map container
    display flex
    width 100% 
    height 100%
    overflow hidden
    flexDirection row
  map left
    position relative
    display flex
    height 100%
    borderRightStyle solid
    borderRightColor #29283d
    borderRightWidth 1px
    cursor pointer
    alignItems center
  map logoWrapper
    height 80%  
    margin 0 10px 0 20px
  map logo 
    image /images/logo.png
    backgroundPosition left center
    width 60px
    height 100%
  map logoText 
    display flex
    justifyContent center
    flexDirection column
    marginRight 25px
    color white
    fontSize 24
    fontWeight 600
    display none !mobile
  map right
    display flex
    position relative
    flexGrow 1
    flexDirection column
  map rightTop
    display flex
    height 25%
    width 100%
    backgroundColor #29283d
    padding 5 0 5 10
    fontSize 10px
    color grey
    justifyContent center
  map followText
    whiteSpace nowrap
    justifyContent center
    textAlign center
    flexDirection column
    display flex
  map socialMediaIconWrapper
    display flex
    left 10px
    margin 0 30px 0 10px
    alignItems center
    width 100%
    height 100%
  map facebook 
    backgroundImage url('/svgs/facebook.svg')
    backgroundRepeat no-repeat
    backgroundPosition 0% 30%
    backgroundSize contain
    height 75%
    width 75%
    verticalAlign middle
    cursor pointer
  map rightBottom
    display flex
    height 75% 
    width: 100%
  map menuButton
    display flex
    width 33.33%
    justifyContent center
    alignItems center
    cursor pointer
    color white
    color grey !hover
    fontSize 16px
    fontWeight 600
    borderTopStyle solid
    borderTopColor #29283d
    borderTopWidth 1px
    borderRightStyle solid
    borderRightColor #29283d
    borderRightWidth 1px
  map bottom 
    display flex
    position relative
    height 5px
    width 100%
    bottom 0px
  map bottomLine 
    backgroundImage linear-gradient(90deg,#275393 0,#cd122d)
    width 100%
    height 5px
  
  `);

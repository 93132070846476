import * as React from "react";
import { Sheet } from "strcss";
import TitleComponent from "../components/title.component";
import { Link } from "react-router-dom";

export interface IProps {}

export interface IState {
  isActive: boolean;
}

export default class MobileMenu extends React.Component<IProps, IState> {
  private static instance: MobileMenu;

  componentDidMount(): void {
    MobileMenu.instance = this;
  }

  public state: IState = {
    isActive: false,
  };

  public static open(): void {
    MobileMenu.instance.setState({
      isActive: true,
    });
  }

  public static hide(): void {
    MobileMenu.instance.setState({
      isActive: false,
    });
  }

  render() {
    return (
      <div
        className={sheet.maps(sheet.map.mobileMenu)}
        style={
          this.state.isActive === false
            ? { transform: "translateX(-100%)" }
            : { transform: "translateX(0%)" }
        }
      >
        <div className={sheet.map.headerWrapper}>
          <div className={sheet.map.header} />
        </div>
        <div className={sheet.map.items}>
          <Link to={"/"} onClick={MobileMenu.hide}>
            <div className={sheet.map.item}>
              <TitleComponent text={"home"} />
            </div>
          </Link>
          <Link to={"/players"} onClick={MobileMenu.hide}>
            <div className={sheet.map.item}>
              <TitleComponent text={"players"} />
            </div>
          </Link>
        </div>
        <div
          className={sheet.map.close}
          onClick={() => {
            MobileMenu.hide();
          }}
        >
          <TitleComponent text={"X"} />
        </div>
      </div>
    );
  }
}

const sheet = new Sheet(`
  map mobileMenu
    display flex
    flexDirection column
    position fixed
    backgroundColor #203372
    height 100vh
    width 100vw
    zIndex 51
    left 0
    right 0
    transition 0.2 easeInOut
  map headerWrapper 
    display flex
    justifyContent center
    width 100%  
  map header 
    image /images/logo.png
    backgroundPosition left center
    width 60px
    margin auto
    height 100px
    opacity 1  
    flex 0 0 auto
  map items 
    flex 1 1 auto 
    display flex
    flexDirection column
  map item 
    margin 20px
    cursor pointer
  map close 
    display flex
    justifyContent center  
    flex 0 0 auto
    padding 40px
    cursor pointer
`);

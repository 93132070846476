export const enumHelper = new (class enumHelper {
  public convertPosition(position: string): string {
    if (position === "DEFENSE") return "defender";
    else if (position === "GOAL") return "keeper";
    else if (position === "MIDFIELDER") return "midfielder";
    else if (position === "ATTACK") return "attacker";
    else if (position === "TRAINER") return "trainer";
    return "";
  }
})();

import * as React from "react";
import { Sheet } from "strcss";
import HeaderComponent from "./header.component";
import HeaderComponentMobile from "./header-mobile.component";
import MobileMenu from "../statics/mobile-menu";

export interface IProps {
  children: React.ReactNode;
}

export interface IState {}

export default class PageComponent extends React.PureComponent<IProps, IState> {
  render() {
    return (
      <>
        <HeaderComponent />
        <HeaderComponentMobile />
        <MobileMenu />
        <div className={sheet.map.children}>{this.props.children}</div>
      </>
    );
  }
}

const sheet = new Sheet(`
  map children
    minHeight 100%
    width 100% 
    padding 120px 0px
    height 100%
    box-sizing border-box;
    paddingTop 80px !mobile
`);

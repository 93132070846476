import { shuffle } from "lodash";
import { IPlayer } from "lokomotiv-packages";
import * as React from "react";
import { Sheet } from "strcss";
import TitleComponent from "../components/title.component";
import PlayersSliderComponent from "../components/player-slider.component";
import { playerService } from "../services/player.service copy";
import PageComponent from "../components/page.component";
import PageBodyComponent from "../components/page-body.component";

export interface IProps {}

export interface IState {
  players: IPlayer[];
}

export default class HomeView extends React.PureComponent<IProps, IState> {
  private baseUrl = "/images/players/";

  public state: IState = {
    players: [],
  };

  async componentDidMount() {
    const _players = await (await playerService.getAll()).json();
    const _playersWithImagesPaths = shuffle(
      _players.filter((player: IPlayer) => {
        return (
          player.isPlayer === true &&
          player.imagePaths &&
          player.imagePaths.length > 0
        );
      })
    );

    const imagePaths = _playersWithImagesPaths.map((player: IPlayer) => {
      return this.baseUrl + player?.imagePaths?.[0];
    });

    this.preloadImages(imagePaths).then((img) => {
      this.setState({
        players: _playersWithImagesPaths,
      });
    });
  }

  preloadImages(srcs: string[]) {
    function loadImage(src: string) {
      return new Promise(function (resolve, reject) {
        var img = new Image();
        img.onload = function () {
          resolve(img);
        };
        img.onerror = img.onabort = function () {
          reject(src);
        };
        img.src = src;
      });
    }
    var promises = [];
    for (var i = 0; i < srcs.length; i++) {
      promises.push(loadImage(srcs[i]));
    }
    return Promise.all(promises);
  }

  public render() {
    const { players } = this.state;

    return (
      <PageComponent>
        <div className={sheet.map.imageWrapper}>
          <div className={sheet.map.image}>
            <div className={sheet.map.imageEffect} />
          </div>
        </div>
        <PageBodyComponent>
          <div className={sheet.map.partnerWrapper}>
            <TitleComponent text={"main partners"} />
            <div className={sheet.map.partnerImageWrapper}>
              <div
                className={sheet.map.adidas}
                onClick={() => {
                  window.open("https://adidas.nl", "_blank");
                }}
              />
              <div
                className={sheet.map.carnal}
                onClick={() =>
                  window.open("https://steakhousecarnal.nl/", "_blank")
                }
              />
            </div>
          </div>
          <div className={sheet.map.playersWrapper}>
            {players.length > 0 && (
              <PlayersSliderComponent
                showHeaderNav={true}
                players={players}
                title={"players"}
                playerSliderStyle={{ height: "300px" }}
              />
            )}
          </div>
        </PageBodyComponent>
      </PageComponent>
    );
  }
}

const sheet = new Sheet(`
  map imageWrapper
    position relative
    width 100%
    height 68vh
    height 35vh !mobile
  map image 
    image /images/the-boys.jpg
    backgroundPositionY -50px
    backgroundRepeat no-repeat
    backgroundSize cover
    width 100%
    height 100%
    zIndex 0
    backgroundPosition center !mobile
  map imageEffect
    position absolute
    opacity 0.9
    bottom -1px
    zIndex 1
    height 80% 
    width 100% 
    backgroundImage linear-gradient(to bottom, rgba(255,0,0,0), #203372)
  map nextMatchWrapper
    position absolute
    display flex
    bottom 0
    color black
    width 100%
    fontWeight 900
    textAlign center
    justifyContent center
    marginBottom 20px
    marginBottom 0px !mobile
    zIndex 2
  map nextMatchBox 
    position relative 
    display flex
    flexDirection column
    opacity 0.8
    width 40%
    maxWidth 500px
    height 60%
    background white
    bottom -20px
    width 80% !mobile
  map nextMatchText
    backgroundColor black
    color white
    fontSize 1.1rem
    fontWeight 900
    padding 5px 0 5px 0
    padding 0 !mobile
  map nextMatchContent
    padding 10px 
  map date
    fontSize 14px  
    marginBottom 20px
    marginBottom 0 !mobile
  map clubs
    display flex
    flexDirection row
    width 100%
    margin 10px auto 0 auto
    justifyContent space-between
  map versus
    flex 1 1 auto
  map homeClub
    width 170px 
  map awayClub
    width 170px 
  map divider
    width 60%
    height 1px
    backgroundColor black
    margin 20px auto 20px auto 
    margin 10px auto 10px auto !mobile
    display none !mobile
  map nextMatchTimer
    display none !mobile  
  map partnerWrapper
    width 100%
    textAlign center  
    margin 40px 0 20px 0
  map partnerImageWrapper
    height 80px  
    height 60px !mobile
    display flex
    flexDirection row
    textAlign center
    justifyContent center
  map adidas
    image /svgs/adidas.svg
    color black
    height 100%
    width 120px
    cursor pointer
    opacity 0.4
    opacity 1 !hover
  map carnal
    image /svgs/carnal.svg
    color black
    height 100%
    width 120px 
    cursor pointer
    opacity 0.4
    opacity 1 !hover
  map playersWrapper 
    width 100%
    margin 40px 0 
  map cupsWrapper
    position relative
    display flex
    justifyContent center
    flexDirection column
    alignItems center
    width 100%
    height 20vh
    textAlign center  
    height 30vh !mobile
  map line
    width 100%
    height 1px
    margin auto
    backgroundColor black
    margin 10px 0 10px 0  
    zIndex 0 
  map shakeAnimation 
    animation shake 2s
    animationIterationCount infinite
`);

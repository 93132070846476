import { ICrate, IResponse } from "lokomotiv-packages";
import { CrudService } from "./crud.abstract.service";
import { httpService } from "./http.service";

export const crateService = new (class CrateService extends CrudService<
  ICrate
> {
  constructor() {
    super("crates");
  }

  async getForPlayer(playerId: string): Promise<IResponse<ICrate[]>> {
    const _url = this.controller + "/players/" + playerId;
    return httpService.getRequest<ICrate[]>(_url);
  }
})();

import * as React from "react";
import { Sheet } from "strcss";

export interface IProps {
  children: React.ReactNode;
}

export default class PageBodyComponent extends React.PureComponent<IProps, {}> {
  render() {
    return <div className={sheet.map.children}>{this.props.children}</div>;
  }
}

const sheet = new Sheet(`
  map children
    maxWidth 1400
    margin 0 auto
`);

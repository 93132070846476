import { IHttpOptions, IResponse } from "lokomotiv-packages";
import { httpService } from "./http.service";

export abstract class CrudService<IModel> {
  constructor(protected readonly controller: string) {}

  /**
   * Fetches a list of all models
   */
  public getAll(options: IHttpOptions = {}): Promise<IResponse<IModel[]>> {
    return httpService.getRequest<IModel[]>(this.controller, options);
  }

  /**
   * Fetches a specific model by id
   * @param id
   */
  public get(
    id: string,
    options: IHttpOptions = {}
  ): Promise<IResponse<IModel>> {
    return httpService.getRequest<IModel>(
      [this.controller, id].join("/"),
      options
    );
  }

  /**
   * Fetches a specific model by id
   * @param id
   */
  public getMany(
    ids: string[],
    options: IHttpOptions = {}
  ): Promise<IResponse<IModel[]>> {
    return httpService.getRequest<IModel[]>(
      [this.controller, "many", ids.join(",")].join("/"),
      options
    );
  }

  /**
   * Creates a new model
   * @param model
   */
  public create(
    model: IModel,
    options: IHttpOptions = {}
  ): Promise<IResponse<IModel>> {
    return httpService.postRequest<IModel>(this.controller, model, options);
  }

  /**
   * Updates a specific model
   * @param model
   */
  public update(
    model: IModel,
    options: IHttpOptions = {}
  ): Promise<IResponse<IModel>> {
    return httpService.putRequest<IModel>(this.controller, model, options);
  }

  // /**
  //  * Deletes a specific model
  //  * @param id
  //  */
  // public delete(
  //   id: string,
  //   options: IHttpOptions = {}
  // ): Promise<IResponse<IModel>> {
  //   return httpService.deleteRequest<IModel>(
  //     [this.controller, id].join("/"),
  //     options
  //   );
  // }
}

import { ICrate, IPlayer } from "lokomotiv-packages";
import * as React from "react";
import { crateService } from "../services/crateRanking.service";
import { playerService } from "../services/player.service copy";
import PlayerStatisticsComponent from "../components/player-statistics.component";
import PlayerDetailHeaderComponent from "../components/player-detail-header.component";
import { useNavigate, useParams } from "react-router-dom";
import PageComponent from "../components/page.component";

export interface IState {
  player: IPlayer | null;
  openCrates?: ICrate[];
}

const PlayerDetailView = () => {
  const params = useParams<{ id: string }>();
  const [player, setPlayer] = React.useState<IPlayer>();
  const [crates, setCrates] = React.useState<ICrate[]>();
  const navigate = useNavigate();

  const memoizedClosedCrates = React.useMemo(
    () => (crates ?? []).filter((crate) => crate.handedInOn),
    [crates]
  );

  const memoizedOpenCrates = React.useMemo(
    () => (crates ?? []).filter((crate) => !crate.handedInOn),
    [crates]
  );

  React.useEffect(() => {
    const init = async () => {
      try {
        const { id } = params;
        if (!id) {
          navigate("/", { replace: true });
          return;
        }

        const player = await (await playerService.get(id)).json();

        if (!player) {
          navigate("/", { replace: true });
        }

        const crates = await (
          await crateService.getForPlayer(player._id)
        ).json();

        setPlayer(player);
        setCrates(crates);
      } catch {
        navigate("/", { replace: true });
      }
    };
    console.log("init");
    init();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (player === null || crates === null) {
    return <React.Fragment />;
  }

  return (
    <PageComponent>
      {player && crates && (
        <React.Fragment>
          <PlayerDetailHeaderComponent player={player} />
          <PlayerStatisticsComponent
            player={player}
            closedCrates={memoizedClosedCrates}
            openCrates={memoizedOpenCrates}
          />
        </React.Fragment>
      )}
    </PageComponent>
  );
};

export default PlayerDetailView;

import * as React from "react";
import { Sheet } from "strcss";
import { IPlayer } from "lokomotiv-packages";

export interface IProps {
  player: IPlayer;
}

export interface IState {
  isLoading: boolean;
}

export default class PlayerDetailHeaderComponent extends React.PureComponent<
  IProps,
  IState
> {
  public state: IState = {
    isLoading: true,
  };

  private transparentPath = "/images/players/transparent/";
  private imageRef: React.RefObject<HTMLDivElement>;
  private nameRef: React.RefObject<HTMLDivElement>;

  constructor(props: IProps) {
    super(props);
    this.imageRef = React.createRef<HTMLDivElement>();
    this.nameRef = React.createRef<HTMLDivElement>();
  }

  async componentDidMount() {
    const { player } = this.props;
    console.log(this.imageRef);
    console.log(this.nameRef);
    if (!this.imageRef.current || !this.nameRef.current) {
      return;
    }
    this.imageRef.current.style.display = "block";
    this.imageRef.current.style.animationName = "bounce";
    this.imageRef.current.style.animationDuration = "1.5s";
    this.imageRef.current.style.animationTimingFunction = "linear";

    if (window.innerWidth < 500 && player.lastName.length < 9) {
      this.nameRef.current.style.fontSize = "68px";
    }
  }

  render() {
    const { player } = this.props;
    const _path = this.transparentPath + player.transparentImages?.[0];
    return (
      <div className={sheet.map.wrapper}>
        <div className={sheet.map.playerDetailheader}>
          <div className={sheet.map.playerWrapper}>
            <div className={sheet.map.top}>
              <div className={sheet.map.name} ref={this.nameRef}>
                {player.lastName}
              </div>
            </div>
            <div className={sheet.map.bottom}>
              <div className={sheet.map.number}>{player.number}</div>
              <div className={sheet.map.fullNameWrapper} />
            </div>
            <div
              className={sheet.map.playerImage}
              style={{ backgroundImage: `url(${_path})` }}
              ref={this.imageRef}
            />
          </div>
        </div>
      </div>
    );
  }
}

const sheet = new Sheet(`
  map playerDetailheader 
    display flex
    justifyContent center
    flexDirection column
    margin auto
    // margin 0 !mobile
  map playerWrapper
    display flex
    flexDirection column
    position relative
    margin auto
    width 90vw
    height 60vh
    overflow hidden
  map top 
    position relative
    flexGrow 1
  map bottom
    flexGrow 2   
  map nameNumberWrapper
    display flex
    flexDirection column
    justifyContent center
    flexGrow 1
  map name number
    textAlign center
    zIndex -1
    fontFamily superstar 
    background linearGradient(180deg,hsla(0,0%,93%,.2),hsla(0,0%,100%,0) 90%)
    -webkitTextFillColor transparent
    -webkitBackgroundClip text
    color #fff
    whiteSpace nowrap
    flexGrow 1
    userSelect none
  map name 
    overflow hidden  
    fontSize 180px
    fontSize 50px !mobile
  map number 
    display none !mobile 
    fontSize 160px   
  map playerImage 
    position absolute
    backgroundPosition right
    backgroundPosition center bottom !mobile
    backgroundSize contain 
    backgroundRepeat noRepeat 
    height 80%
    width 100%
    bottom 0
  map statics 
    width 70vw 
    display flex
    flexDirection column
    alignItems center
    justifyContent center
    background rgba(0,0,0, 0.5)
    margin auto
    padding 10 50
  map text
    marginBottom 10px
    color white
    fontSize 40px
  map crates
    display none !mobile 
    display flex
    width 300px
    height 100px
    left 100px
    bottom 100px
  map open closed
    width 150px
    height 100px
  map amount 
    color #fdc52c  
    fontSize 60px
    textAlign center
    fontFamily superstar    
  map openText closedText 
    color white 
    fontSize 18px
    textTransform uppercase
    fontFamily superstar    
    textAlign center  
`);

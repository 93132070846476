import * as React from "react";
import { Sheet } from "strcss";

export default class LoaderComponent extends React.PureComponent<{}, {}> {
  render() {
    return (
      <div className={sheet.map.loaderWrapper}>
        <div className={sheet.map.loader} />
      </div>
    );
  }
}

const sheet = new Sheet(`
  map loaderWrapper
    display flex
    justifyContent center  
    backgroundColor #203372;
    height 50vh
    alignItems center
  map loader 
    animation heartbeat 3s infinite
    image /resources/images/logo.png
    size 100
  
`);
